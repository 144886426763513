export const environment = {
  appVersion: '#{{VERSION}}#',
  production: true,
  //api:
  apiUrl: 'https://fd-slp-crb-qa-e9bvghb9arhrhmaw.a01.azurefd.net/api',
  apiVersion: '1.0',
  //Okta:
  clientId: '0oa6b7v6cnuCZomlw697',
  issuer: 'https://crb-dev-viewaccount.okta.com/oauth2/aus6c174fntK3kmxT697',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,

  // Google Analytics
  measurementId: 'G-7D2BTWHQLL',

  //Space Chat
  spaceChat: 'https://d85dmso4lcu7a.cloudfront.net/scripts/ChatModule_c_u.js',
  spaceAuthenticatedChat: 'https://d85dmso4lcu7a.cloudfront.net/scripts/ChatModule_c_a.js',

  //Slider
  sliderDelay: 5000
}
